.home-page {
  .special-product {
    margin-top: $sp120;
    text-align: center;

    .product-item-group {
      .ant-row {
        justify-content: center;

        .product-item {
          position: relative;
          height: 100%;
          cursor: pointer;

          .product-item-image {
            width: 100%;
            height: 120px;
            border-radius: 4px;
            overflow: hidden;

            .img-item {
              height: 100%;
              width: 100%;
              background-position: center;
              background-size: cover;
              transition: all 500ms ease 0s;
              transform: scale(1);
            }
          }

          .product-item-title {
            font-weight: $semiBold;
            font-size: $fontMP;
            color: rgba($color: $black, $alpha: 0.9);
          }

          .border-bottom {
            position: absolute;
            bottom: 0;
            height: 4px;
            width: 100%;
            background-color: $primary;
            transition: all 500ms ease 0s;
            transform: scale(0);
            opacity: 0;
          }

          &:hover {
            border-color: $primary;

            .img-item {
              transition: all 500ms ease 0s;
              transform: scale(1.2);
            }

            .border-bottom {
              transition: all 500ms ease 0s;
              transform: scale(0.5);
              opacity: 1;
            }

            .product-item-title {
              color: $primary;
            }
          }
        }
      }
    }
  }

  .text-animation {
    margin-top: $sp120;

    @keyframes masked-animation {
      0% {
        background-position: 0px -200px;
      }

      50% {
        background-position: 0px -100px;
      }

      100% {
        background-position: 0px -200px;
      }
    }

    h1 {
      margin: 0 auto;
      width: fit-content;
      background-image: url("../../assets/images/text-most-people.jpeg");
      background-size: cover;
      text-transform: uppercase;
      font-weight: 900;
      text-align: center;
      font-size: 80px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-animation-name: masked-animation;
      animation-name: masked-animation;
      -webkit-animation-duration: 10s;
      animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
  }

  .home-about {
    margin-top: $sp120;
    position: relative;

    .bg-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.1;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .home-about-image {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .home-about-content {
      margin-top: $sp44;
      font-size: $fontM;

      .view-more {
        width: 100%;
        text-align: end;
      }
    }
  }

  .quality-show {
    background-color: $yellow;
    height: 300px;
    margin-top: $sp120;

    .container {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .quality-item {
        text-align: center;

        img {
          opacity: 0.6;
          display: block;
          height: 120px;
          margin: 0 auto;
          margin-bottom: $sp24;
        }

        span {
          font-size: $fontM;
          font-weight: $semiBold;
          color: rgba($color: $black, $alpha: 0.8);
          text-transform: uppercase;

          &.emphasize-item {
            font-weight: $bold;
            font-size: $fontL;
            color: $black;
          }
        }
      }
    }
  }

  .home-product {
    margin-top: $sp120;

    .product-item {
      height: 300px;
      overflow: hidden;
      position: relative;

      .item-image {
        height: 100%;
        width: 100%;
        border-radius: 3px;
        background-position: center;
        background-size: cover;
        transition: all 500ms ease 0s;
        transform: scale(1);
        cursor: pointer;

        .item-text {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 500ms ease 0s;
          background-color: rgba($color: $primary, $alpha: 0);
          padding: $sp32;
          text-align: center;

          span {
            font-size: $fontL;
            font-weight: $bold;
            color: $white;
            text-transform: uppercase;
          }
        }
      }

      &:hover {
        .item-image {
          transition: all 500ms ease 0s;
          transform: scale(1.1);
        }

        .item-text {
          transition: all 500ms ease 0s;
          background-color: rgba($color: $primary, $alpha: 0.7);
        }
      }
    }
  }

  .home-posts {
    margin-top: $sp120;
    padding: $sp120 0;
    text-align: center;
    background-color: rgba($color: $primary, $alpha: 0.1);

    .post-group {
      margin-top: $sp44;
    }
  }

  // Responsive
  @media only screen and (max-width: 991px) {
    .text-animation {
      @keyframes masked-animation {
        0% {
          background-position: 0px -100px;
        }

        50% {
          background-position: 0px 0px;
        }

        100% {
          background-position: 0px -100px;
        }
      }

      h1 {
        font-size: 72px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .text-animation {
      margin-top: $sp60;

      h1 {
        font-size: 60px;
      }
    }
  }

  @media only screen and (max-width: 575px) {
    .text-animation {
      @keyframes masked-animation {
        0% {
          background-position: 0px -50px;
        }

        50% {
          background-position: 0px 0px;
        }

        100% {
          background-position: 0px -50px;
        }
      }

      h1 {
        font-size: 40px;
        -webkit-animation-duration: 6s;
        animation-duration: 6s;
      }
    }
  }
}
