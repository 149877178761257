.about-page {
  .about-content {
    span {
      font-size: $fontM;
    }
  }

  .about-bg {
    width: 100%;
    height: 100%;
    min-height: 300px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}
