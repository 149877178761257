.news-details {
  font-size: $fontM;

  .quote-text {
    color: rgba($color: $black, $alpha: 0.7);
  }

  .post-related {
    margin-top: $sp60;
  }
}
