// Colors
$primary: #115f37;
$black: #333333;
$white: #ffffff;
$yellow: #fcdb5a;
$brow: #666666;
$red: #d9363e;

// Font Size
$fontS: 12px;
$fontM: 16px;
$fontMP: 20px;
$fontL: 24px;
$fontXL: 36px;
$fontXXL: 56px;

// Font weight
$light: 300;
$regular: 400;
$semiBold: 600;
$bold: 900;

// Spacing
$sp12: 12px;
$sp16: 16px;
$sp20: 20px;
$sp24: 24px;
$sp28: 28px;
$sp32: 32px;
$sp36: 36px;
$sp40: 40px;
$sp44: 44px;
$sp48: 48px;
$sp52: 52px;
$sp56: 56px;
$sp60: 60px;
$sp80: 80px;
$sp96: 96px;
$sp112: 112px;
$sp120: 120px;

// Alignments
%verticalCenter {
  display: flex;
  align-items: center;
}

// Text
%textEllipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
