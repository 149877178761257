.product-group {
  margin-top: $sp24;

  .product-item {
    height: 100%;
    border: 1px solid $primary;
    border-radius: 3px;
    transition: all 500ms ease 0s;

    .product-image {
      overflow: hidden;
      height: 250px;
      border-radius: 3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product-content {
      padding: $sp24;

      .content-title {
        font-size: $fontMP;
        font-weight: $semiBold;
      }

      .content-price {
        font-size: $fontMP;
        font-weight: $bold;

        .sell-price {
          color: $red;
          text-decoration: line-through;
        }
      }

      .product-button {
        text-align: center;
      }
    }

    &:hover {
      transition: all 500ms ease 0s;
      box-shadow: 0 0 8px 2px rgba($primary, 0.5);
    }
  }
}

.product-details {
  .product-images {
    border: 1px solid $primary;

    .carousel-item {
      width: 100%;
      height: 450px;
      padding: $sp32;
      border-radius: 3px;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .image-gallery-slide-wrapper {
      .image-gallery-slide {
        height: 430px;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .product-info {
    .category-label {
      font-size: $fontM;
      font-weight: $semiBold;
      color: rgba($black, 0.8);
    }

    .descriptions-text {
      display: block;
      font-size: $fontM;
      margin-top: $sp32;
      color: rgba($black, 0.8);
    }

    .input-number {
      display: block;
      margin-top: $sp32;
      height: 50px;
      width: 64px;
      font-size: $fontMP;
      line-height: 46px;
      border: 1px solid rgba($primary, 0.5);
      border-radius: 3px;

      .ant-input-number-handler-wrap {
        opacity: 1;
        border: none;

        .ant-input-number-handler {
          border: none;

          .anticon {
            font-size: $fontMP;
            color: $primary;
          }
        }
      }
    }

    .app-button {
      display: block;
      margin-top: $sp24;
    }
  }

  .details-info {
    margin-top: $sp60;
    font-size: $fontM;
  }

  .related-products {
    margin-top: $sp60;
  }
}

.order-product-modal {
  .ant-modal-close-x {
    svg {
      fill: $white;
    }
  }

  .ant-modal-header {
    background-color: $primary;

    .ant-modal-title {
      font-size: $fontMP;
      font-weight: $bold;
      color: $white;
    }
  }

  .ant-modal-body {
    .order-form {
      input {
        height: 44px;
        font-size: $fontM;
      }

      .text-area {
        height: 100px;
      }

      .ant-form-item-label {
        label {
          font-size: $fontM;
          font-weight: $semiBold;
        }
      }
    }

    .ant-typography {
      font-size: $fontM;
    }
  }

  .ant-modal-footer {
    .ant-btn-primary {
      font-weight: $semiBold;
    }
  }
}
