html,
body {
  margin: 0;
  padding: 0;
}

#root {
  .container {
    max-width: 1400px;
    width: 100%;
    height: 100%;
    padding: 0 $sp16;
    margin: 0 auto;
  }

  .app-button {
    min-width: 180px;
    min-height: 60px;
    padding: 8px 24px;
    background-color: transparent;
    color: $white;
    font-weight: $semiBold;
    font-size: $fontMP;
    border: 2px solid $white;
    text-transform: uppercase;
    border-radius: 50px;
    color: $white;
    font-size: $fontM;
    font-weight: $semiBold;

    &:hover {
      background-color: $primary;
    }

    &.revert {
      border-color: $primary;
      color: $primary;

      &:hover {
        color: $white;
      }
    }
  }

  .ant-result-404 {
    padding-top: 120px;
    background-color: $black;

    .ant-result-title {
      font-size: $fontXL;
      color: $white;
      font-weight: $semiBold;
    }

    .ant-result-subtitle {
      font-size: $fontM;
      color: $white;
    }
  }
}

.app-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $black;
  z-index: 99999;
  opacity: 0.5;
}

// Component Styles
.app-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 80px;

  .app-header-row {
    display: flex;
    align-items: center;

    .logo {
      height: 80px;
      width: 80px;
      padding: 8px;
      background-color: $white;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .menu {
      .ant-menu-overflow {
        justify-content: flex-end;
        border: none;
        background-color: transparent;
        color: white;
        font-weight: $semiBold;
        text-transform: uppercase;
      }
    }
  }
}

.app-footer {
  padding-bottom: $sp60;

  .contact-group {
    text-align: center;
    background-color: $brow;
    padding: $sp120 0;

    .app-title {
      span {
        color: rgba($white, 0.8) !important;
      }

      .app-title-sub {
        .ant-typography {
          color: rgba($white, 0.8);
        }
      }
    }

    .contact-form {
      margin-top: $sp60;
      max-width: 700px;
      margin: 0 auto;

      .ant-input {
        border-bottom: 1px solid rgba($white, 0.8);
        font-size: $fontM;
        padding: $sp12 0;
        color: rgba($white, 0.8);
        font-weight: $semiBold;

        &::placeholder {
          color: rgba($white, 0.8);
        }

        &.text-area {
          background-color: transparent;
          height: 120px;
        }
      }

      .ant-form-item-explain {
        text-align: left;
      }

      button {
        width: 100%;
      }
    }
  }

  .footer-group {
    padding-top: $sp60;

    .footer-left {
      .left-top {
        display: flex;

        .logo {
          height: 130px;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }

        .infomation {
          padding: $sp16 $sp32;
          margin-left: $sp24;

          .info-item {
            display: flex;
            align-items: center;
            color: $black;
            margin: 8px 0;

            .info-icon {
              font-size: $fontMP;
              margin-right: $sp12;
              color: $primary;
            }

            .info-text {
              font-size: $fontM;
            }
          }
        }
      }

      .left-bottom {
        margin-top: $sp44;

        span {
          color: rgba($black, 0.6);
        }
      }
    }

    .footer-right {
      padding-top: $sp16;
      margin-bottom: $sp32;

      .get-letter {
        font-size: $fontMP;
        text-transform: uppercase;
        font-weight: $bold;
      }

      .subscribe-form {
        margin-top: $sp32;

        .ant-form-item {
          margin: 0;

          .ant-col {
            display: flex;
            align-items: center;

            .ant-form-item-control-input {
              width: 100%;

              input {
                border-bottom: 1px solid $black;
                font-size: $fontM;
                padding: 8px 0;
                margin-top: 16px;

                &::placeholder {
                  color: $brow;
                }
              }
            }

            .ant-form-item-explain {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.contact-pinner {
  position: fixed;
  right: 0;
  top: 200px;
  background-color: rgba($white, 0.6);
  padding: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border: 2px solid rgba($primary, 0.6);
  border-right: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transition: all 200ms ease 0s;
  height: 160px;
  width: 60px;

  .pinner-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: all 300ms ease 0s;
    transform: scale(1);

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    &:hover {
      transition: all 300ms ease 0s;
      transform: scale(1.3);
    }
  }

  &:hover {
    transition: all 200ms ease 0s;
    height: 180px;
    width: 70px;
  }
}

.post-item {
  height: 100%;
  cursor: default;

  .post-image-container {
    position: relative;
    overflow: hidden;
    height: 300px;

    .post-image {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-position: center;
      background-size: cover;
      border-radius: 3px;
      transition: all 500ms ease 0s;
      transform: scale(1);
    }

    .post-date {
      position: absolute;
      left: $sp16;
      background-color: $primary;
      padding: $sp24 $sp12 0;
      text-align: center;

      .ant-typography {
        color: $white;
        font-weight: $semiBold;
        line-height: 16px;

        .post-day {
          font-weight: $bold;
          font-size: $fontL;
        }
      }
    }
  }

  .post-content {
    position: relative;
    background-color: $white;
    padding: $sp24 $sp16 $sp44 $sp16;
    margin-left: $sp20;
    margin-right: $sp20;
    border-radius: 3px;
    transition: all 500ms ease 0s;
    margin-top: -$sp44;

    .post-title {
      font-size: $fontMP;
      font-weight: $semiBold;
    }

    .post-text {
      font-size: $fontM;
    }
  }

  &:hover {
    .post-image {
      transition: all 500ms ease 0s;
      transform: scale(1.2);
    }

    .post-content {
      transition: all 500ms ease 0s;
      margin-top: -$sp80;
    }
  }
}

.header-submenu-list {
  .ant-menu-item {
    .ant-menu-title-content {
      font-weight: $semiBold;
    }

    &:hover {
      background-color: rgba($primary, 0.2);
    }
  }
}

.app-carousel {
  .slick-slide {
    .carousel-item {
      position: relative;
      display: flex !important;
      align-items: center;
      justify-content: center;
      min-height: 600px;
      height: 100vh;

      .carousel-content {
        z-index: 3;
        padding: 12vw;
        text-align: center;

        .ant-typography {
          color: $white;

          &.ty-title {
            font-size: 6vw;
            font-weight: $bold;
            letter-spacing: 8px;
            margin-bottom: $sp24;
          }

          &.ty-sub {
            font-weight: 300;
            max-width: 700px;
            margin: 0 auto $sp44 auto;
          }
        }
      }

      .overlay {
        position: absolute;
        background: url("./assets/images/carousel-overlay.png") center center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0.5;
        overflow: hidden;
        z-index: 0;
      }

      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all 4000ms ease 0s;
        transform: scale(2);
        opacity: 0;
        z-index: -1;
      }
    }

    &.slick-active {
      .carousel-item {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transition: all 4000ms ease 0s;
          transform: scale(1);
          opacity: 1;
        }
      }
    }
  }
}

.app-title {
  display: inline-block;
  margin-bottom: $sp24;

  span {
    text-transform: uppercase;
    font-weight: 200;
    color: rgba($color: $black, $alpha: 0.6);
  }

  .app-title-sub {
    max-width: 500px;

    .ant-typography {
      font-size: $fontM;
      font-weight: $regular;
      text-transform: none;
      color: rgba($color: $black, $alpha: 0.5);
    }
  }
}

.child-page {
  .banner {
    height: 475px;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    padding: $sp60;

    span {
      font-size: $fontXXL;
      color: rgba($white, 0.9);
      line-height: 64px;
      font-weight: 200;

      &.bigger-banner-title {
        margin-top: -20px;
      }
    }

    .banner-sub-title {
      font-size: $fontM;
      font-weight: $regular;
      line-height: normal;
      max-width: 700px;
      margin-top: $sp24;
    }
  }

  .child-content {
    margin-top: $sp120;
    padding-bottom: $sp60;
  }
}

.news-post-content {
  img {
    //max-width: 1vw;
    margin-left: 16px;
    margin-right: 16px;
  }
}

// Responsive

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
}
